<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Add Subject
          <div class="card-header-actions">
            <CLink
              class="card-header-action btn-minimize"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
            </CLink>
            <CLink
              href="#"
              class="card-header-action btn-close"
              v-on:click="show = false"
            >
              <CIcon name="cil-x" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
            <form>
              <div class="form-group row">
                <label
                  for="teacherNameFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Select Class</label
                >
                <div class="col-sm-8">
                   <select class="form-control"  v-model="form.class_id">
                        <option value=''>Select Class</option>
                          <option
                        v-for="(y,i) in class_list"
                        v-bind:key="i"
                        :value="y.class_id"
                    >{{y.class_standard}}</option>
                    </select>
                </div>
              </div>
              <div class="form-group row">
                <label
                  for="classFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Subject Name</label
                >
                <div class="col-sm-8">
                 <input type ="text" name="school" class="form-control" placeholder="Subject Name"  v-model="form.subject">
                </div>
              </div>
              <!-- end form-group -->

             

              <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                  <CButton color="primary" size="md" class="border-0" @click="save_data1">
                    Submit
                  </CButton>&nbsp;&nbsp;
                  <CButton color="light" size="md" class="border-0" @click="reset">
                    Reset
                  </CButton>&nbsp;&nbsp;
                </div>
              </div>
            </form>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
import $ from "jquery";
import Swal from 'sweetalert2'; 
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
    form: {
        subject:"",
        class_id:""
      },
      show: true,
      isCollapsed: true,
      class_list:[],

    };
  },
  methods:{
      reset(){
        this.form.subject = "";
        this.form.class_id = "";
      },
      save_data1(){
        if(this.para == '' || this.para == undefined){
            // add API will be call
            var data = {
                'subject_name': this.form.subject,
                'class_id': this.form.class_id
                
            };
            console.log(data);
            var promise = apis.add_Subjects(data)
            .then( response => {
               Swal.fire({
                  width:200,
                  title: 'Subject Added Sucessfully !!',
                  Text: 'Subject Added Sucessfully !!'
                });
             this.reset();
             //this.$router.go(-1);

            }).catch(error => {

            });
          
        }else{
            // edit API will be call
            //  var data = {
            //    'school_id': this.id,
            //     'school_name': this.form.schoolName,
            //     'address': this.form.schoolAddress,
            //     'contact_no': this.form.contact,
            //     'contact_person': this.form.contactPerson,
            //     'district_id': '00000000-0000-0000-0000-000000000000'
            // };
            // console.log(data);
            // axios.put(base_url + 'api/Schools/'+this.id,data).then( response => {
            //   this.isLoading = false;
              
            //   this.$alert("School Updated Sucessfully");
            //   this.$router.go(-1);

            // }).catch(error => {

            // });
        }
      },
      onSubmit(event) {
        event.preventDefault();
       
        if(this.para == '' || this.para == undefined){
            // add API will be call
            var data = {
                'subject_name': this.form.subject,
                'class_id': this.form.class_id
                
            };
            console.log(data);
            var promise = apis.add_Subjects(data)
            .then( response => {
              this.$alert("Subject Added Sucessfully");
              this.$router.go(-1);

            }).catch(error => {

            });
          
        }else{
            // edit API will be call
             var data = {
               'school_id': this.id,
                'school_name': this.form.schoolName,
                'address': this.form.schoolAddress,
                'contact_no': this.form.contact,
                'contact_person': this.form.contactPerson,
                'district_id': '00000000-0000-0000-0000-000000000000'
            };
            console.log(data);
            axios.put(base_url + 'api/Schools/'+this.id,data).then( response => {
              this.isLoading = false;
              
              this.$alert("School Updated Sucessfully");
              this.$router.go(-1);

            }).catch(error => {

            });
        }
           
    },
   
    get_class(){
       var promise = apis.list_ClassMasters().then( response => {
          this.class_list = response.data;
      }).catch( errror => {

      })
    },
  
  },
  created(){
    this.get_class();
     

  }
};
</script>
